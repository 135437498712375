@import '../../../../scss/theme-bootstrap';

.button-link {
  display: inline-block;
  margin: 0 10px 30px;
  @include breakpoint($landscape-up) {
    margin: 0 7px 30px;
  }
  &-mobile {
    display: inline-block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &-pc {
    display: none;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
  }
}
